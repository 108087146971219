import {Product} from 'app/blocks/model/product.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IProduct} from 'app/blocks/model/product.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {Observable} from 'rxjs';
import {IProductAttribute} from 'app/blocks/model/product-attirbute.model';
import {IImage} from 'app/blocks/model/image.model';
import {IVideo} from 'app/blocks/model/video.model';

@Injectable({
    providedIn: 'root'
})
export class ProductService extends EntityCrudService<IProduct> {
    constructor(http: HttpClient) {
        super(http, Resource.PRODUCTS, (obj?) => new Product(obj));
    }

    uploadFile = (label: string, file: File, productId: number, attributes: IProductAttribute[]): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('name', label);
        if (attributes && attributes.length > 0) {
            fd.append('attributes', JSON.stringify(attributes));
        }
        return this.http.post<any>(`${SERVER_API_URL}api/products/${productId}/docs`, fd).toPromise();
    };

    uploadProductImage = (name: string, sequence: number | undefined, file: File, attributes: IProductAttribute[]): Observable<IImage> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('name', name);
        if (sequence != null) {
            fd.append('sequence', sequence.toString());
        }
        if (attributes && attributes.length > 0) {
            fd.append('attributes', JSON.stringify(attributes));
        }
        return this.http.post<IImage>(`${SERVER_API_URL}api/products/image`, fd);
    };

    uploadProductVideo = (name: string, sequence: number | undefined, file: File): Observable<IVideo> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('name', name);
        if (sequence != null) {
            fd.append('sequence', sequence.toString());
        }
        return this.http.post<IVideo>(`${SERVER_API_URL}api/products/video`, fd);
    };

    downloadFile = (productId: number, imageId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/products/${productId}/docs/${imageId}`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    deleteFile = (productId: number, imageId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/products/${productId}/docs/${imageId}`;
        return this.http.delete(REQUEST_URI).toPromise();
    };

    downloadImage(productId: number, imageId: number): Observable<any> {
        const REQUEST_URI = `${SERVER_API_URL}api/products/${productId}/docs/${imageId}`;
        return this.http.get(REQUEST_URI, {responseType: 'blob'});
    }
    setProductsInactive = (productIds): any => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/products/inactive', productIds, {
                observe: 'response'
            })
            .toPromise();
    };
    getInactiveProducts = (durationInMonths = '6'): any => {
        return this.http
            .get(`${SERVER_API_URL}api/products/no-orders`, {
                observe: 'response',
                params: {
                    durationInMonths: durationInMonths
                }
            })
            .toPromise();
    };
    getProductRouteWarehouseStock = (routeId, productIds): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('routeId', routeId);
        params = params.append('productIds', productIds);
        return this.http
            .get(SERVER_API_URL + 'api/products/stock', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getProductWarehouseStock = (warehouseId, productIds, productCondition): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('warehouseId', warehouseId);
        params = params.append('productIds', productIds);
        if (productCondition != null) {
            params = params.append('condition', productCondition);
        }
        return this.http
            .get(SERVER_API_URL + 'api/products/warehouse-stock', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getProductUnitPrice = (requestDate, deliveryPointId, productQtyMap): Promise<HttpResponse<any>> => {
        return this.http
            .post<any>(
                SERVER_API_URL + 'api/products/price',
                {
                    deliveryPointId: deliveryPointId,
                    productQtyMap: productQtyMap,
                    requestDate: requestDate
                },
                {observe: 'response'}
            )
            .toPromise();
    };

    getProductsForProductGroup = (
        pageNumber,
        size,
        productGroupId,
        onPromotion = 'false',
        showNonStockable = 'true',
        customerId?,
        includeIds: number[] = null,
        sortBy?,
        inactive?
    ): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('productGroup', productGroupId);
        params = params.append('page', pageNumber);
        params = params.append('size', size);
        params = params.append('isOnPromotion', onPromotion);
        params = params.append('showNonStockable', showNonStockable);
        if (inactive) {
            params = params.append('inactive', inactive);
        }
        if (customerId) {
            params = params.append('customerId', customerId);
        }
        if (includeIds != null && includeIds.length > 0) {
            params = params.append('includeIds', includeIds.join(','));
        }
        if (sortBy === 'retainProductSequence') {
            sortBy = 'code';
        }
        if (sortBy) {
            const sortParam = `${sortBy},asc`;
            params = params.append('sort', sortParam);
        }
        return this.http
            .get(SERVER_API_URL + 'api/products', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getProductsForProductClass = (
        pageNumber,
        size,
        productClassId,
        onPromotion = 'false',
        showNonStockable = 'true',
        customerId?,
        includeIds: number[] = null,
        sortBy?,
        inactive?
    ): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('productClassId', productClassId);
        params = params.append('page', pageNumber);
        params = params.append('size', size);
        params = params.append('isOnPromotion', onPromotion);
        params = params.append('showNonStockable', showNonStockable);
        if (inactive) {
            params = params.append('inactive', inactive);
        }
        if (customerId) {
            params = params.append('customerId', customerId);
        }
        if (includeIds != null && includeIds.length > 0) {
            params = params.append('includeIds', includeIds.join(','));
        }
        if (sortBy === 'retainProductSequence') {
            sortBy = 'code';
        }
        if (sortBy) {
            const sortParam = `${sortBy},asc`;
            params = params.append('sort', sortParam);
        }
        return this.http
            .get(SERVER_API_URL + 'api/products', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getProductsForSupplier = (
        pageNumber,
        size,
        supplierId,
        onPromotion = false,
        showNonStockable = true,
        customerId?,
        includeIds: number[] = null,
        sortBy?
    ): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('supplier', supplierId);
        params = params.append('page', pageNumber);
        params = params.append('size', size);
        params = params.append('isOnPromotion', onPromotion);
        params = params.append('showNonStockable', showNonStockable);
        if (customerId) {
            params = params.append('customerId', customerId);
        }
        if (includeIds != null && includeIds.length > 0) {
            params = params.append('includeIds', includeIds.join(','));
        }
        if (sortBy === 'retainProductSequence') {
            sortBy = 'code';
        }
        if (sortBy) {
            const sortParam = `${sortBy},asc`;
            params = params.append('sort', sortParam);
        }
        return this.http
            .get(SERVER_API_URL + 'api/products', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getProductsForCustomer = (
        pageNumber,
        size,
        onPromotion = 'false',
        showNonStockable = 'true',
        customerId?,
        sortBy?,
        showInactive = 'false'
    ): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('page', pageNumber);
        params = params.append('size', size);
        params = params.append('isOnPromotion', onPromotion);
        params = params.append('showNonStockable', showNonStockable);
        params = params.append('inactive', showInactive);
        if (customerId) {
            params = params.append('customerId', customerId);
        }
        if (sortBy === 'retainProductSequence') {
            sortBy = 'code';
        }
        if (sortBy) {
            const sortParam = `${sortBy},asc`;
            params = params.append('sort', sortParam);
        }
        return this.http
            .get(SERVER_API_URL + 'api/products', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    searchProductsByText = (
        pageNumber,
        size,
        text,
        onPromotion = 'false',
        customerId?,
        deliveryPointId?,
        sortBy?,
        searchFields?,
        showInactive?,
        showReturnsOnly?
    ): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('query', text);
        params = params.append('page', pageNumber);
        params = params.append('size', size);
        params = params.append('isOnPromotion', onPromotion);
        if (showInactive) {
            params = params.append('showInactive', showInactive);
        }
        if (customerId) {
            params = params.append('customerId', customerId);
        }
        if (deliveryPointId) {
            params = params.append('deliveryPointId', deliveryPointId);
        }
        if (sortBy === 'retainProductSequence') {
            sortBy = 'code';
        }
        if (showReturnsOnly) {
            params = params.append('showReturnsOnly', showReturnsOnly);
        }
        if (sortBy) {
            const sortParam = `${sortBy},asc`;
            params = params.append('sort', sortParam);
        }
        if (Array.isArray(searchFields)) {
            const lowercaseSearchFields = searchFields.map((field) => field.toLowerCase());
            const searchFieldsParam = lowercaseSearchFields.join(',');
            params = params.append('searchFields', searchFieldsParam);
        }
        return this.http
            .get(SERVER_API_URL + 'api/_search/products', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getCommissionPercentage = (id: number): Promise<number> => {
        return this.http.get<number>(`${SERVER_API_URL}api/products/${id}/commission-percentage`).toPromise();
    };

    getProductsInWarehouse = (warehouseId): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('warehouseId', warehouseId);
        return this.http
            .get(SERVER_API_URL + 'api/products/warehouse-products', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getProductsInWarehouseByPage = (pageNumber, size, warehouseId): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('warehouseId', warehouseId);
        params = params.append('page', pageNumber);
        params = params.append('size', size);
        return this.http
            .get(SERVER_API_URL + 'api/products/warehouse-products-page', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getProduct = (productId: any): Promise<HttpResponse<any>> => {
        return this.http
            .get(SERVER_API_URL + 'api/products/' + productId, {
                observe: 'response'
            })
            .toPromise();
    };

    public downloadProductDataPDFs = (productIds: number[]): any => {
        const requestParams = new HttpParams().set('ids', productIds.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/products/download-PDF`;
        return this.http.get(REQUEST_URI, {
            responseType: 'arraybuffer',
            params: requestParams
        });
    };

    public downloadAllProductDataPDFs = (): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/products/download-all`;
        return this.http.get(REQUEST_URI, {
            responseType: 'arraybuffer'
        });
    };

    public markProductsAsActive = (productIds: number[]): any => {
        const params = new HttpParams().set('activeStatus', true);
        return this.http
            .post<any>(SERVER_API_URL + 'api/products/mark-as-active-or-inactive', productIds, {
                params: params,
                observe: 'response'
            })
            .toPromise();
    };

    public markProductsAsInactive = (productIds: number[]): any => {
        const params = new HttpParams().set('activeStatus', false);
        return this.http
            .post<any>(SERVER_API_URL + 'api/products/mark-as-active-or-inactive', productIds, {
                params: params,
                observe: 'response'
            })
            .toPromise();
    };

    public markProductsAsReturnable = (productIds: number[]): any => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/products/mark-as-returnable', productIds, {
                observe: 'response'
            })
            .toPromise();
    };

    public markProductsAsOnPromotion = (productIds: number[]): any => {
        return this.http
            .post<any>(SERVER_API_URL + 'api/products/on-promotions', productIds, {
                observe: 'response'
            })
            .toPromise();
    };

    getAllProductsByIds = (productIds: any): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('productIds', productIds);
        return this.http
            .get(SERVER_API_URL + 'api/all-products/', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getWarehouseBinLocationsFromProductIdsAndWarehouseIdOrRouteId = (
        productIds: number[],
        warehouseId: number,
        routeId: number
    ): Promise<HttpResponse<any>> => {
        const requestBody = {
            productIds: productIds,
            warehouseId: warehouseId,
            routeId: routeId
        };
        return this.http
            .post<any>(SERVER_API_URL + 'api/warehouse-bin-locations/multiple-products', requestBody, {
                observe: 'response'
            })
            .toPromise();
    };
    getProductsBySupplierId = (supplierId: number): Promise<HttpResponse<any>> => {
        return this.http
            .get(SERVER_API_URL + 'api/products/supplier/' + supplierId, {
                observe: 'response'
            })
            .toPromise();
    };

    public downloadProductBarcodes = (productIds: number[]): any => {
        const requestParams = new HttpParams().set('ids', productIds.join(','));
        const REQUEST_URI = `${SERVER_API_URL}api/products/download-product-barcodes`;
        return this.http.get(REQUEST_URI, {
            responseType: 'blob',
            params: requestParams
        });
    };

    getProductPickedQuantity = (productIds): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('productIds', productIds);
        return this.http
            .get(SERVER_API_URL + 'api/products/picked-quantity', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getProductsTaxRates = (productIds): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('productIds', productIds);
        return this.http
            .get(SERVER_API_URL + 'api/products/tax-rates', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getProductUnitCost = (productQtyMap, supplierId): Promise<HttpResponse<any>> => {
        return this.http
            .post<any>(
                SERVER_API_URL + 'api/products/unit-cost',
                {
                    productQtyMap: productQtyMap,
                    supplierId: supplierId
                },
                {observe: 'response'}
            )
            .toPromise();
    };
}
