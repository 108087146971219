import {ICGSTCategory} from 'app/blocks/model/cgst-category.model';
import {IProductClass, ProductClass} from 'app/blocks/model/product-class.model';
import {IProductTaxCategory, ProductTaxCategory} from 'app/blocks/model/product-tax-category.model';
import {IUnitOfMeasure, UnitOfMeasure} from 'app/blocks/model/unit-of-measure.model';
import {IProductList, ProductList} from 'app/blocks/model/product-list.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IProductGroup} from 'app/blocks/model/product-group.model';
import {IDurationProductPriceInfo} from 'app/blocks/model/duration-product-price-info.model';
import {IProductPackaging} from 'app/blocks/model/product-packaging.model';
import {IImage} from 'app/blocks/model/image.model';
import {ISupplier} from 'app/blocks/model/supplier.model';
import {ISGSTCategory} from 'app/blocks/model/sgst-category.model';
import {IIGSTCategory} from 'app/blocks/model/igst-category.model';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IProductDoubleUomBreakdown} from 'app/blocks/model/product-double-uom-breakdown.model';
import {IBrand} from 'app/blocks/model/brand.model';
import {IVideo} from 'app/blocks/model/video.model';

type TaxRateSpec = {
    ukVat: IProductTaxCategory;
    roiVat: IProductTaxCategory;
    inIgst: IIGSTCategory;
    inSgst: ISGSTCategory;
    inCgst: ICGSTCategory;
    gctTax: IProductTaxCategory;
};

export type IMediaAsset = IImage | IVideo;

export interface IProduct extends IBaseEntity {
    id?: number;
    code?: string;
    secondaryCode?: string;
    handheldDescription?: string;
    statementDescription?: string;
    miniDescription?: string;
    vendorProductCode?: string;
    isBuyableProduct?: boolean;
    isStockableProduct?: boolean;
    isSellableProduct?: boolean;
    masterStockingProductQuantity?: number;
    baseProductQuantity?: number;
    loadRefProductQuantity?: number;
    fractionalQtySalesAllowed?: boolean;
    upc?: number;
    ean?: number;
    isbn?: string;
    mpn?: string;
    barcode?: string;
    hsnCode?: string;
    manufacturer?: string;
    brand?: IBrand;
    isHandheldProduct?: boolean;
    inactive?: boolean;
    reorderLevel?: number;
    weight?: number;
    volume?: number;
    uomQty?: number;
    purchaseQty?: number;
    cost?: number;
    transferCost?: number;
    minimumPrice?: number;
    maximumPrice?: number;
    exciseTax?: number;
    productClass?: IProductClass;
    taxCategory?: IProductTaxCategory;
    cgst?: ICGSTCategory;
    sgst?: ISGSTCategory;
    igst?: IIGSTCategory;
    gctTax?: IProductTaxCategory;
    unitOfMeasure?: IUnitOfMeasure;
    masterStockingProduct?: IProduct;
    baseProduct?: IProduct;
    loadRefProduct?: IProduct;
    weightUnit?: IUnitOfMeasure;
    volumeUnit?: IUnitOfMeasure;
    productList?: IProductList;
    productGroups?: IProductGroup[];
    priceInfos?: IDurationProductPriceInfo[];
    packagings?: IProductPackaging[];
    useBatch?: boolean;
    photos?: IImage[];
    supplier?: ISupplier;
    isCatchWeight: boolean;
    defaultPrice?: number;
    secondTierVolumeThreshold?: number;
    secondTierPrice?: number;
    thirdTierVolumeThreshold?: number;
    thirdTierPrice?: number;
    taxRates: TaxRateSpec;
    sequence: number;
    binLocations?: any[];
    onPromotion?: boolean;
    returnable?: boolean;
    meanWeight: number;
    tolerance: number;
    returnPrice: number;
    useReturnPrice: boolean;
    doubleUom: boolean;
    uomMultiplicationFactor: number;
    caseBarcode: string;
    discount?: number;
    enableBatchInfo: boolean;
    enableAdl: boolean;
    imeiTrackingEnabled: boolean;
    tieredPricingEnabled: boolean;
    unNumber?: string;
    psnClass?: string;
    packingGroup?: string;
    tunnelCode?: string;
    amendableInFutureOrder?: boolean;
    salesNominalCode?: string;
    purchaseNominalCode?: string;
    productDoubleUomBreakdowns?: IProductDoubleUomBreakdown[];
    color?: string;
    returnsOnly?: boolean;
    shipperMultiplicationFactor?: number;
    shipperBarcode?: string;
    showStockFromDefaultWarehouse?: boolean;
    recommendedRetailPrice?: number;
    isDepositItem?: boolean;
    maxTimeInVehicleInSeconds?: number;
    rackNo?: string;
    ondcProductDomain?: string;
    ondcProductCategory?: string;
    manufacturerOrPackerName?: string;
    manufacturerOrPackerAddress?: string;
    manufacturePackingImportDate?: any;
    ingredientInfo?: string;
    nutritionalInfo?: string;
    additiveInfo?: string;
    importerFssaiLicenseNo?: string;
    brandOwnerFssaiLicenseNo?: string;
    shelfLife: number;
    isADREnabled?: boolean;
    netWeightKg?: number;
    videos?: IVideo[];
}

export class Product extends BaseFranchiseEntity implements IProduct {
    public id?: number;
    public code?: string;
    public secondaryCode?: string;
    public handheldDescription?: string;
    public statementDescription?: string;
    public miniDescription?: string;
    public vendorProductCode?: string;
    public isBuyableProduct?: boolean;
    public isStockableProduct?: boolean;
    public isSellableProduct?: boolean;
    public masterStockingProductQuantity?: number;
    public baseProductQuantity?: number;
    public loadRefProductQuantity?: number;
    public fractionalQtySalesAllowed?: boolean;
    public upc?: number;
    public ean?: number;
    public isbn?: string;
    public mpn?: string;
    public barcode?: string;
    public hsnCode?: string;
    public manufacturer?: string;
    public brand?: IBrand;
    public isHandheldProduct?: boolean;
    public inactive?: boolean;
    public reorderLevel?: number;
    public weight?: number;
    public volume?: number;
    public uomQty?: number;
    public purchaseQty?: number;
    public cost?: number;
    public productClass?: IProductClass;
    public taxCategory?: IProductTaxCategory;
    public cgst?: ICGSTCategory;
    public sgst?: ISGSTCategory;
    public igst?: IIGSTCategory;
    public unitOfMeasure?: IUnitOfMeasure;
    public masterStockingProduct?: IProduct;
    public baseProduct?: IProduct;
    public loadRefProduct?: IProduct;
    public weightUnit?: IUnitOfMeasure;
    public volumeUnit?: IUnitOfMeasure;
    public productList?: IProductList;
    public productGroups?: IProductGroup[];
    public priceInfos?: IDurationProductPriceInfo[];
    public packagings?: IProductPackaging[];
    public useBatch?: boolean;
    public photos?: IImage[];
    public supplier?: ISupplier;
    public transferCost?: number;
    public minimumPrice?: number;
    public maximumPrice?: number;
    public exciseTax?: number;
    public isCatchWeight: boolean;
    public defaultPrice?: number;
    public secondTierVolumeThreshold?: number;
    public secondTierPrice?: number;
    public thirdTierVolumeThreshold?: number;
    public thirdTierPrice?: number;
    public taxRates: TaxRateSpec;
    public sequence: number;
    public binLocations?: any[];
    public onPromotion?: boolean;
    public returnable?: boolean;
    public meanWeight: number;
    public tolerance: number;
    public returnPrice: number;
    public useReturnPrice: boolean;
    public doubleUom: boolean;
    public uomMultiplicationFactor: number;
    public caseBarcode: string;
    public discount: number;
    public enableBatchInfo: boolean;
    public enableAdl: boolean;
    public imeiTrackingEnabled: boolean;
    public tieredPricingEnabled: boolean;
    public unNumber: string;
    public psnClass: string;
    public packingGroup: string;
    public tunnelCode: string;
    public amendableInFutureOrder?: boolean;
    public gctTax?: IProductTaxCategory;
    public salesNominalCode?: string;
    public purchaseNominalCode?: string;
    public productDoubleUomBreakdowns?: IProductDoubleUomBreakdown[];
    public color?: string;
    public returnsOnly?: boolean;
    public shipperMultiplicationFactor?: number;
    public shipperBarcode?: string;
    public showStockFromDefaultWarehouse?: boolean;
    public recommendedRetailPrice?: number;
    public isDepositItem?: boolean;
    public maxTimeInVehicleInSeconds?: number;
    public rackNo?: string;
    public ondcProductDomain?: string;
    public ondcProductCategory?: string;
    public manufacturerOrPackerName?: string;
    public manufacturerOrPackerAddress?: string;
    public manufacturePackingImportDate?: any;
    public ingredientInfo?: string;
    public nutritionalInfo?: string;
    public additiveInfo?: string;
    public importerFssaiLicenseNo?: string;
    public brandOwnerFssaiLicenseNo?: string;
    public shelfLife: number;
    public isADREnabled?: boolean;
    public netWeightKg?: number;
    public videos?: IVideo[];

    constructor(product?) {
        super();
        if (product) {
            this.id = product.id;
            this.code = product.code;
            this.secondaryCode = product.secondaryCode;
            this.handheldDescription = product.handheldDescription;
            this.statementDescription = product.statementDescription;
            this.miniDescription = product.miniDescription;
            this.vendorProductCode = product.vendorProductCode;
            this.isBuyableProduct = product.isBuyableProduct;
            this.isStockableProduct = product.isStockableProduct;
            this.isSellableProduct = product.isSellableProduct;
            this.masterStockingProductQuantity = product.masterStockingProductQuantity;
            this.baseProductQuantity = product.baseProductQuantity;
            this.loadRefProductQuantity = product.loadRefProductQuantity;
            this.fractionalQtySalesAllowed = product.fractionalQtySalesAllowed;
            this.upc = product.upc;
            this.ean = product.ean;
            this.isbn = product.isbn;
            this.mpn = product.mpn;
            this.barcode = product.barcode;
            this.hsnCode = product.hsnCode;
            this.manufacturer = product.manufacturer;
            this.brand = product.brand;
            this.isHandheldProduct = product.isHandheldProduct;
            this.inactive = product.inactive;
            this.reorderLevel = product.reorderLevel;
            this.weight = product.weight;
            this.volume = product.volume;
            this.uomQty = product.uomQty;
            this.purchaseQty = product.purchaseQty;
            this.cost = product.cost;
            this.productClass = product.productClass;
            this.taxCategory = product.taxCategory;
            this.cgst = product.cgst;
            this.sgst = product.sgst;
            this.igst = product.igst;
            this.unitOfMeasure = product.unitOfMeasure;
            this.masterStockingProduct = product.masterStockingProduct;
            this.baseProduct = product.baseProduct;
            this.loadRefProduct = product.loadRefProduct;
            this.weightUnit = product.weightUnit;
            this.volumeUnit = product.volumeUnit;
            this.productList = product.productList;
            this.productGroups = product.productGroups;
            this.priceInfos = product.priceInfos;
            this.packagings = product.packagings;
            this.photos = product.photos || [];
            this.useBatch = product.useBatch || false;
            this.supplier = product.supplier;
            this.transferCost = product.transferCost;
            this.minimumPrice = product.minimumPrice;
            this.maximumPrice = product.maximumPrice;
            this.exciseTax = product.exciseTax;
            this.isCatchWeight = product.isCatchWeight;
            this.defaultPrice = product.defaultPrice;
            this.secondTierVolumeThreshold = product.secondTierVolumeThreshold;
            this.secondTierPrice = product.secondTierPrice;
            this.thirdTierVolumeThreshold = product.thirdTierVolumeThreshold;
            this.thirdTierPrice = product.thirdTierPrice;
            this.taxRates = product.taxRates || {
                ukVat: null,
                roiVat: null,
                inIgst: null,
                inSgst: null,
                inCgst: null,
                gctTax: null
            };
            this.sequence = product.sequence;
            this.binLocations = product.binLocations;
            this.onPromotion = product.onPromotion;
            this.returnable = product.returnable;
            this.meanWeight = product.meanWeight;
            this.tolerance = product.tolerance;
            this.returnPrice = product.returnPrice;
            this.useReturnPrice = product.useReturnPrice;
            this.editable = product.editable;
            this.doubleUom = product.doubleUom;
            this.uomMultiplicationFactor = product.uomMultiplicationFactor;
            this.caseBarcode = product.caseBarcode;
            this.discount = product.discount;
            this.enableBatchInfo = product.enableBatchInfo;
            this.enableAdl = product.enableAdl;
            this.imeiTrackingEnabled = product.imeiTrackingEnabled;
            this.tieredPricingEnabled = product.tieredPricingEnabled;
            this.unNumber = product.unNumber;
            this.psnClass = product.psnClass;
            this.packingGroup = product.packingGroup;
            this.tunnelCode = product.tunnelCode;
            this.amendableInFutureOrder = product.amendableInFutureOrder;
            this.gctTax = product.gctTax;
            this.salesNominalCode = product.salesNominalCode;
            this.purchaseNominalCode = product.purchaseNominalCode;
            this.productDoubleUomBreakdowns = product.productDoubleUomBreakdowns;
            this.color = product.color;
            this.returnsOnly = product.returnsOnly;
            this.shipperMultiplicationFactor = product.shipperMultiplicationFactor;
            this.shipperBarcode = product.shipperBarcode;
            this.showStockFromDefaultWarehouse = product.showStockFromDefaultWarehouse;
            this.recommendedRetailPrice = product.recommendedRetailPrice;
            this.isDepositItem = product.isDepositItem;
            this.maxTimeInVehicleInSeconds = product.maxTimeInVehicleInSeconds;
            this.rackNo = product.rackNo;
            this.ondcProductDomain = product.ondcProductDomain;
            this.ondcProductCategory = product.ondcProductCategory;
            this.manufacturerOrPackerName = product.manufacturerOrPackerName;
            this.manufacturerOrPackerAddress = product.manufacturerOrPackerAddress;
            this.manufacturePackingImportDate = product.manufacturePackingImportDate;
            this.ingredientInfo = product.ingredientInfo;
            this.nutritionalInfo = product.nutritionalInfo;
            this.additiveInfo = product.additiveInfo;
            this.importerFssaiLicenseNo = product.importerFssaiLicenseNo;
            this.brandOwnerFssaiLicenseNo = product.brandOwnerFssaiLicenseNo;
            this.shelfLife = product.shelfLife;
            this.isADREnabled = product.isADREnabled;
            this.netWeightKg = product.netWeightKg;
            this.videos = product.videos;
        } else {
            this.productGroups = [];
            this.priceInfos = [];
            this.packagings = [];
            this.binLocations = [];
            this.isStockableProduct = true;
            this.returnable = true;
            this.useReturnPrice = false;
            this.taxRates = {
                ukVat: null,
                roiVat: null,
                inIgst: null,
                inSgst: null,
                inCgst: null,
                gctTax: null
            };
        }
    }
    get discriminator(): any {
        return this.code + ' - ' + this.statementDescription;
    }
    clone(): Product {
        return new Product(this);
    }
}

export enum OndcProductDomain {
    GROCERY = 'GROCERY',
    F_AND_B = 'F_AND_B'
}

export enum OndcProductCategory {
    FRUIT_AND_VEGETABLES = 'FRUIT_AND_VEGETABLES',
    MASALA_AND_SEASONING = 'MASALA_AND_SEASONING',
    OIL_AND_GHEE = 'OIL_AND_GHEE',
    GOURMET_AND_WORLD_FOODS = 'GOURMET_AND_WORLD_FOODS',
    FOOD_GRAINS = 'FOOD_GRAINS',
    EGGS_MEAT_AND_FISH = 'EGGS_MEAT_AND_FISH',
    CLEANING_AND_HOUSEHOLD = 'CLEANING_AND_HOUSEHOLD',
    BEVERAGES = 'BEVERAGES',
    BEAUTY_AND_HYGIENE = 'BEAUTY_AND_HYGIENE',
    BAKERY_CAKES_AND_DIARY = 'BAKERY_CAKES_AND_DIARY',
    KITCHEN_ACCESSORIES = 'KITCHEN_ACCESSORIES',
    BABY_CARE = 'BABY_CARE',
    SNACKS_AND_BRANDED_FOODS = 'SNACKS_AND_BRANDED_FOODS',
    PET_CARE = 'PET_CARE',
    STATIONERY = 'STATIONERY'
}
