import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ProductService} from 'app/blocks/service/api/product.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IProduct} from 'app/blocks/model/product.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Product} from 'app/blocks/model/product.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class ProductResource extends AbstractEntityResource<IProduct> {
    constructor(productService: ProductService, router: Router) {
        super(
            Resource.PRODUCTS,
            productService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.PRODUCTS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.PRODUCTS),
            (entity?) => new Product(entity),
            (entity) => entity.code + ' - ' + entity.statementDescription,
            'local_grocery_store'
        );
    }
}
