import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IProductAttributeType} from 'app/blocks/model/product-attribute-type.model';

export interface IProductClass extends IBaseEntity {
    id?: number;

    className?: string;

    description?: string;

    commissionPercentage?: number;

    sequenceNo?: number;

    color?: string;

    restrictedInForwardOrder?: boolean;

    parentId?: number;

    attributeTypes?: IProductAttributeType[];
}

export class ProductClass extends BaseFranchiseEntity implements IProductClass {
    public id?: number;

    public className?: string;

    public description?: string;

    public commissionPercentage?: number;

    public sequenceNo?: number;

    public color?: string;

    public restrictedInForwardOrder?: boolean;

    public parentId?: number;

    public attributeTypes?: IProductAttributeType[];

    constructor(productClass?) {
        super();
        if (productClass) {
            this.id = productClass.id;

            this.className = productClass.className;

            this.description = productClass.description;

            this.commissionPercentage = productClass.commissionPercentage;

            this.sequenceNo = productClass.sequenceNo;

            this.color = productClass.color;

            this.restrictedInForwardOrder = productClass.restrictedInForwardOrder;

            this.editable = productClass.editable;

            this.parentId = productClass.parentId;
        }
    }

    get discriminator(): any {
        return this.className;
    }

    clone(): ProductClass {
        return new ProductClass(this);
    }
}
